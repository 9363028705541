.landing-page {
  /* Landing Page */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
  min-height: 100vh; /* Minimum height of the viewport */
  padding: 15px; /* Increase padding to add more vertical space */
}

.flair {
  position: relative;
  width: 100vw;
  height: 30vh; /* Adjust the height as needed */
  overflow: hidden;
}

.flair::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right bottom,
    #900603 25%,
    #002266 50%,
    #ffffff 100%
  ); /* Adjusted gradient */
  transform: skewY(-5deg); /* Skew the gradient */
}

.custom-heading {
  font-family: "Montserrat", sans-serif;
  /* Add any other desired styles */
}
